/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl'
import { Box, Flex, Heading } from 'rebass/styled-components'
import { Element, scroller } from 'react-scroll'

import {
  Wrapper,
  Content,
  Aside,
  Title,
  Price,
  ProductId,
  TaxInfo,
  Description,
} from './styles'
import calculateTotal from './calculate-total'
import AccessoriesSelector from './accessories-selector'
import NextProduct from './next-product'
import Section from '../section'
import Carousel from '../carousel'
import Quantity from '../quantity'
import Share from '../share'
import PaymentButton from '../payment-button'
import Order from '../order'
import PropertiesTable from '../properties-table'
import { isBrowser } from '../../utils/isBrowser'
// import countryIsInEU from '../../utils/countryIsInEU'
import Arrow from '../arrow'

const Product = (props) => {
  const {
    env,
    intl,
    title,
    html,
    properties,
    sku,
    price,
    currency,
    shippingPrice,
    freeShippingTotal,
    video,
    photogallery,
    nextProduct,
    accessories,
  } = props
  const { locale: currentLocale } = intl

  // Can be product bought with PayPal?
  const [canBuy] = useState(false)

  // This shows Order form
  const [canOrder] = useState(true)

  // Determines quentity in order
  const [quantity, setQuantity] = useState(1)

  // Hold chosen accessories if present
  const [chosenAccessories, setChosenAccessories] = useState([])

  // Tax of order
  const [taxRate, setTaxRate] = useState(0)

  const items = [
    {
      title,
      price,
      quantity,
      shippingPrice,
      accessories,
      chosenAccessories,
    },
  ]

  const { subtotal, total, isFreeShipping, tax, shipping } = calculateTotal(
    items,
    shippingPrice,
    freeShippingTotal,
    taxRate,
  )

  const onToggleOrder = () => {
    scroller.scrollTo('orderScroll', {
      duration: 500,
      offset: -16,
      smooth: 'easeInOutQuint',
    })
  }

  const onCountryChange = (country) => {
    const baseTaxRate = 0.21

    if (country) {
      setTaxRate(baseTaxRate)
    }

    // if (countryIsInEU(country)) {
    //   setTaxRate(baseTaxRate)
    // } else {
    //   setTaxRate(0)
    // }
  }

  const handleChooseAccessory = (id) => {
    let chosen = []

    accessories.forEach((a, i) => {
      if (a.id === id) {
        if (chosenAccessories.includes(id)) {
          chosen.splice(i, 1)
        } else {
          chosen.push(a.id)
        }
      }
    })

    setChosenAccessories(chosen)
  }

  return (
    <>
      <Section py={[3, 4, 5]}>
        <Wrapper>
          <Content>
            {photogallery && <Carousel slides={photogallery} video={video} />}
          </Content>

          <Aside>
            <Element name="orderScroll" />
            <Title>{title}</Title>

            <Flex mb={3} justifyContent="space-between" alignItems="center">
              <Box>
                <Price>
                  <FormattedNumber
                    style="currency"
                    value={price}
                    currency={currency}
                    minimumFractionDigits={currentLocale === 'cs' ? 0 : 2}
                    maximumFractionDigits={currentLocale === 'cs' ? 0 : 2}
                  />
                </Price>

                <Flex>
                  <ProductId>ID: {sku || '-'}</ProductId>
                  <TaxInfo>
                    <FormattedMessage
                      id="product.priceWithoutTax"
                      defaultMessage="Price without tax"
                    />
                  </TaxInfo>
                </Flex>
              </Box>

              <Box flexGrow={0}>
                <Quantity
                  onChange={(q) => !isNaN(q) && q > 0 && setQuantity(q)}
                />
              </Box>
            </Flex>

            {accessories && (
              <Box sx={{ mb: 4 }}>
                <Heading as="h2" variant="accessoriesTitle">
                  <FormattedMessage
                    id="product.accessories"
                    defaultMessage="Accessories"
                  />
                </Heading>

                <AccessoriesSelector
                  accessories={accessories}
                  currentLocale={currentLocale}
                  chosenAccessories={chosenAccessories}
                  onSelect={(id) => handleChooseAccessory(id)}
                />
              </Box>
            )}

            <Box mb={4}>
              {canOrder && (
                <Order
                  onToggleOrder={onToggleOrder}
                  onCountryChange={(country) => onCountryChange(country)}
                  quantity={quantity}
                  subtotal={subtotal}
                  tax={tax}
                  taxRate={taxRate}
                  total={total}
                  currency={currency}
                  productTitle={title}
                  price={price}
                  shipping={shipping}
                  legibleFreeShipping={isFreeShipping}
                  sku={sku}
                  accessories={accessories}
                  chosenAccessories={chosenAccessories}
                />
              )}

              {canBuy && (
                <PaymentButton
                  currency={currency}
                  title={title}
                  total={total}
                  quantity={quantity}
                  price={price}
                  shippingPrice={shippingPrice}
                  env={env}
                />
              )}
            </Box>

            <Description mb={4} dangerouslySetInnerHTML={{ __html: html }} />

            <PropertiesTable properties={properties || []} mb={4} />

            {isBrowser && (
              <Share
                title={title}
                url={isBrowser ? location.href : 'https://shopsharpblades.com/'}
              />
            )}

            {nextProduct && <NextProduct nextProduct={nextProduct} />}
          </Aside>
        </Wrapper>

        <Flex as="footer" justifyContent="center" width="100%" my={4}>
          <Link to="/collection/">
            <Flex alignItems="center">
              <Arrow left />
              <FormattedMessage
                id="product.backToCollection"
                defaultMessage="Back to collection"
              />
            </Flex>
          </Link>
        </Flex>
      </Section>
    </>
  )
}

Product.propTypes = {
  env: PropTypes.string,
  currency: PropTypes.string,
  price: PropTypes.number.isRequired,
  freeShippingTotal: PropTypes.number.isRequired,
  properties: PropTypes.array,
  data: PropTypes.object,
  video: PropTypes.string,
  photogallery: PropTypes.array,
  nextProduct: PropTypes.object,
  accessories: PropTypes.array,
  buyExternalLink: PropTypes.string,
  intl: PropTypes.object,
}

Product.defaultProps = {
  buyExternalLink: null,
}

export default injectIntl(Product)
